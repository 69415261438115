import React, { Component } from "react";
import { NavLink } from 'react-router-dom';

class RcTimer extends Component {
	state = {screenOpen: false,}

	takePhoto = () => {
	  this.setState({buttonPressed: !this.state.buttonPressed});
	}

	render() {
		return (
			<div className="page-padding animation--fade">
				<div className="center-text">
					<h1 className="decorated">RC Timer</h1>
				</div>

				<div className="center-text">
					<h2><b>Note:</b> work in progress</h2>
				</div>

				<div className="link--return">
					<NavLink exact to={`/projects`}>
						Return
					</NavLink>
				</div>

				<div className="panel panel--is-decorated panel--is-shadow panel--has-border">
					<div className="panel__content">
						<h2>Overview</h2>
						<p>RC Timer is a hardware and software Arduino-based rc car lap timer, built primarily for the Kyosho Mini-Z platform, but compatible with any sized car that fits with a reasonable degree of clearance over the gantry.</p>
						<p>The goal of it is very straightforward - track a car's lap times. I've since added additional functionality, including screens for the cars with countdown timers, race tracking (including laps remaining and best time), as well as a secondary segment display more easily read from an First-Person View (FPV) camera.</p>

						<div className="container container--has-2-items container--feature-left container--is-centred container--is-image">
							<div className="image">
								<img src="https://s3-ap-southeast-2.amazonaws.com/nik.malyaris.com/images/rc-timer/rc-timer-system.jpg" alt="rc-timer-system"></img>
							</div>

							<div className="content">
								<p>The basic concept of the system is a pair (or multiple pairs) of laser emitters and photo-sensors, which create a 'beam' across the track at different heights, which when broken by a 'flag' attached to a car will trigger the end of the lap.</p>
							</div>
						</div>

						<p>Currently, it is set up to handle two cars, however more can be added. The primary limiting factor is the spacing required for each beam and ports available on the Arduino.</p>
						<p>The ports issue can be fixed by using a different board with more ports, but the spacing issue is more of a problem. The cars aren't always at the same height, they bounce around a bit, and attaching a flag at the end of what is essentially a long lever just exacerbates the issue. The bigger and more stable the car (and obviously the larger the gantry), the more you could fit.</p>

						<div className="container container--has-2-items container--feature-right container--is-centred container--is-image">
							<div className="content">
								<p>The system differentiates between different cars by placing the beams at different heights, and calibrating the photosensors's sensitivity such that a thin pole will not trip the system, but a 'flag' of some sort attached to the end will.</p><p>This way we can have multiple cars using the same gantry without tripping each other's sensors (by placing their flags and sensor pairs at different heights).</p>
							</div>

							<div className="image">
								<img src="https://s3-ap-southeast-2.amazonaws.com/nik.malyaris.com/images/rc-timer/rc-timer-diagram.jpg" alt="rc-timer-diagram"></img>
							</div>
						</div>

						<p>With the current setup, five pairs could comfortably fit, but any more than that and you'd run into issues with delineating the beams (e.g. if a car is bouncing a bit from bumps as it crosses the finish line, it could trip the wrong sensor, or in fact multiple sensors).</p>
					</div>
				</div>

				<div className="panel panel--is-decorated panel--is-shadow panel--has-border">
					<div className="panel__content">
						<h2>Hardware</h2>
						<p>The system is based on the Arduino platform, and uses a number of 'plug in' pysical modules. The part list, not including wires or other connections / boards are as follows:</p>
						<ul>
							<li>Arduino Uno (any type with enough ports will do)</li>
							<li>LCD Display</li>
							<li>Segment Display</li>
							<li>Buzzer</li>
							<li>2x LED Laser</li>
							<li>2x Photosensor</li>
							<li>Gantry Materials</li>
						</ul>

						<p>The Arduino Uno is the main brains of the operation. Any Arduino with enough ports would do here, I just happened to have this on hand. Cheaping out on this is ill-advisable, as a high polling rate is critical, especially with the faster moving rc cars.</p>
						<p>LCD & Segment Displays are for output, mostly of lap times and count down. The Segment Display was added later because the low bitrate on the FPV cameras I had mounted to the cars meant they had terrible dynamic range, and you couldn't make out the small details of the LCD screen. The buzzer was added for similar reasons, just to make it super obvious if the sensors were actually tripping (once the system was well dialed in it became a lot less useful).</p>

						<div className="container container--has-2-items container--is-image">
							<div className="image">
								<img src="https://s3-ap-southeast-2.amazonaws.com/nik.malyaris.com/images/rc-timer/rc-timer-display-segment.jpg" alt="rc-timer-display-segment"></img>
							</div>

							<div className="image">
								<img src="https://s3-ap-southeast-2.amazonaws.com/nik.malyaris.com/images/rc-timer/rc-timer-buzzer.jpg" alt="rc-timer-buzzer"></img>
							</div>
						</div>

						<p>The gantry materials can be anything - even the arch of a table. I however wanted something more permanent that I could drill holes into because lining up the lasers and photosensors is enormously difficult with tape. Some hollow steel rods, a pair of right angle connectors and a pair of t-junction connectors (for feet) make up my gantry).</p>

						<div className="container container--has-2-items container--is-image">
							<div className="image">
								<img src="https://s3-ap-southeast-2.amazonaws.com/nik.malyaris.com/images/rc-timer/rc-timer-lasers.jpg" alt="rc-timer-lasers"></img>
							</div>

							<div className="image">
								<img src="https://s3-ap-southeast-2.amazonaws.com/nik.malyaris.com/images/rc-timer/rc-timer-photosensors.jpg" alt="rc-timer-photosensors"></img>
							</div>
						</div>

						<p>The Lasers and Photosensors are mounted within black boxes in order to isolate them. In the case of the lasers, it's safety (they're not particularly strong as far as lasers go, but more than strong enough to cause damage to the eye). For the photosensors, they weren't originally mounted in boxes, but were added later to isolate them from ambient light as much as possible to reduce false positives or negatives.</p>

						<h3>Wiring Diagram</h3>

						<div className="container container--has-2-items container--feature-right container--is-centred container--is-image">
							<div className="content">
								<p>This isn't a perfect representation of the setup, but gives you a good idea of what's involved.</p><p>The resistors are not required due to the type of photoresistor I'm actually using (the diagram software doesn't have them), and it's missing a buzzer because it got <i>really</i> upset when I included it, but otherwise is fairly accurate.</p>
							</div>

							<div className="image">
							<img src="https://s3-ap-southeast-2.amazonaws.com/nik.malyaris.com/images/rc-timer/rc-timer-wiring-diagram.jpg" alt="rc-timer-wiring-diagram"></img>
							</div>
						</div>

						<p>In my system I'm not using a prototyping breadboard - I'm using a a shield that I can plug in to my arduino so it's all a bit more compact, and I made up a power distribution board for the positives and negatives by chopping up some breadboard.</p>
					</div>
				</div>

				<div className="panel panel--is-decorated panel--is-shadow panel--has-border">
					<div className="panel__content">
						<h2>Software</h2>
						<p>The software for the system is all loaded into the Arduino through their fantastic IDE. </p>
						<p>Though by programming a discrete micro-controller I could eventually remove the Arduino from the equation, this would preclude me from making future updates or changes easily. Instead I bought an extension shield that the arduino can slot in and out of in mere seconds so that it can be used for other projects without requiring a rebuild every time I come back to this one. In addition, the system is still reliant on taking commands through the serial connection to the connected computer - I could add buttons, but the advantages are slim given I don't intend to remove the Arduino from the system anyway.</p>
						<p>I used the following libraries, though anyone trying to create this themselves will need to use libraries relevant to the specific parts they buy. You should be able to get this from the supplier's listing, otherwise google.</p>
						<ul>
							<li>library 1</li>
							<li>library 2</li>
						</ul>
					</div>
				</div>

				<div className="panel panel--is-decorated panel--is-shadow panel--has-border">
					<div className="panel__content">
						<h2>Cars</h2>
						<p>You could use any small scale RC car with this system, so long as it isn't too big and you've got a way of mounting the flags for tripping the detection beams. Personally I bought a pair of integrated FPV cars from my drone parts supplier (actually I bought them first and though I'd really like to have a timing system for them!)</p>
						<p>These were later upgraded to the fantastic, if rather expensive Kyosho Mini-Z platofrm paired with aftermarket FPV Camera + Transmitter AIOs. This gives a much better experience, and feels incredible racing around the mini tracks.</p>
						<p>Protip for anyone who buys one of these Kyosho cars, you absolutely must use good quality rechargable batteries in the car - the difference is night and day with a good set of Eneloops. I had no idea there was such a significant difference between throwaway supermarket batteries and rechargables!</p>

						<div className="container container--has-3-items container--is-image">
							<div className="image">
								<img src="https://s3-ap-southeast-2.amazonaws.com/nik.malyaris.com/images/rc-timer/rc-car-emax.jpg" alt="rc-car-emax"></img>
							</div>

							<div className="image">
								<img src="https://s3-ap-southeast-2.amazonaws.com/nik.malyaris.com/images/rc-timer/rc-car-mini-z.jpg" alt="rc-car-mini-z"></img>
							</div>

							<div className="image">
								<img src="https://s3-ap-southeast-2.amazonaws.com/nik.malyaris.com/images/rc-timer/rc-car-fpv-camera.jpg" alt="rc-car-fpv-camera"></img>
							</div>
						</div>

						<sup><b>Left:</b> Original AIO FPV Car, <b>Center:</b> New Kyosho Mini-Z Car, <b>Right:</b> Standalone AIO Camera & Transmitter (not shown: attached battery tray)</sup>

						<p>The FPV camera is what really makes the system. It has nothing to do with the timing system on a physical or software level, but being able to be 'in' the car so to speak, and see the system operating as a driver adds so much to the whole experience.</p>
						<p>The standalone FPV camera is a combined Camera + Transmitter All In One (AIO), and requires a separate power source. For prototyping, I soldered a AAA battery tray to a connector matching the camera, but eventually I'll wire it into the car itself. This hasn't happened yet because I'm not pleased with the camera's quality, and I'm waiting on a newer model to see if it improves (mainly around dynamic range for viewing the screens).</p>
						<p>The camera is mounted to the roof of the car (sadly even though the windows on the car are transparent, the camera is too large to get it close enough to the window to have any reasonable field of view outside). The first prototype 'flag' was mounted above it, though this will be replaced with a propper flag and pole so multiple cars can race at once.</p>
						<p><a href="https://oscarliang.com/aio-fpv-camera-vtx-combo/">This resource is getting old</a>, given how rapidly camera tech is progressing, but is a good guide on what cameras are worth buying. If at the time of reading there's a good digital option that isn't too expensive, go with that - the thousand dollar price tag is the only thing holding me back from that myself!</p>

						<div className="container container--has-2-items container--is-image">
							<div className="image">
								<img src="https://s3-ap-southeast-2.amazonaws.com/nik.malyaris.com/images/rc-timer/rc-car-camera-mounted.jpg" alt="rc-car-camera-mounted"></img>
							</div>

							<div className="image">
								<img src="https://s3-ap-southeast-2.amazonaws.com/nik.malyaris.com/images/rc-timer/rc-car-prototype-flag.jpg" alt="rc-car-prototype-flag"></img>
							</div>
						</div>
					</div>
				</div>


				<div className="panel panel--is-decorated panel--is-shadow panel--has-border">
					<div className="panel__content">
						<h2>Results</h2>
						<p>For what started as "let's get some fpv cars to rip around the house with during covid lockdown," I ended up taking it a <b>LOT</b> further than I expected. Between the car upgrade, camera upgrade, track upgrade, and building an entire timing system, there's a lot involved here.</p>
						<p>Overall I'm pretty pleased with the result. It's not perfect, it's not polished, and it's certainly no marketable product, but it was a great project I learnt a lot from and really enjoy using.</p>

						<h3>Challenges</h3>
						<p>Given my skills in this area are essentially non-existent, I think I stumbled into every trap and mistake one could possible end up in. That being said, it was a good learning experience and exercise in endless problem solving.</p>
						<p>I couldn't even begin to list every problem I came across, but the major ones were false positives on the photosensor, aligning the lasers and sensors and the aforementioned camera quality problems.</p>
						<p>The camera quality is still not really resolved - I'm waiting to see what new options are released to hopefully buy a new option.</p>
						<p>If I'm honest, the alignment problem isn't really resolved either. I built in a calibration routine on startup to try mitigate it, but until this project gets to a finished state when I can permanently attach the lasers and sensors (likely never), this problem won't be going away.</p>
						<p>Happily, at least one of these was resolved. The false positives problem was pretty much totally solved by placing the photosensors in the back of a black box, and drilling only a very small hole inline with the sensor for the laser. Very little light gets in there now, even when a bright light is in an inconvenient spot in the room.</p>
					</div>
				</div>


				<div className="panel panel--is-decorated panel--is-shadow panel--has-border">
					<div className="panel__content">
						<h2>Part List</h2>
						<p>I'd recommend this project to anyone with at least a small amount of programming experience. It's certainly not impossible to complete without said experience, but the skills of troubleshooting code and embedded systems will help you enormously.</p>
						<p>I've linked my code on github below, along with a schematic of the system, but it most likely won't work off the bat and will require some troubleshooting depending on exactly what parts you buy, how you've conencted everything up, and the versions of the libraries in use.</p>
						<p>The part list below is only going to help you if you live in Australia, and buy not long after posting this (Dec 20). You can swap pretty much any brand or model in and out, just keep in mind you will need to make sure theyhave feature parity, don't use more pins, and make the necessary code moficiations (I can't help you here, it depends on what you buy).</p>
						<p>Required Parts:</p>
						<ul>
							<li><a href="https://store.arduino.cc/usa/arduino-uno-rev3">Arduino Uno</a> (Rev 3)</li>
							<li>2x <a href="https://www.jaycar.com.au/arduino-compatible-red-laser-diode-module/p/XC4490">LED Laser</a> (Duinotech XC-4490)</li>
							<li>2x <a href="https://www.jaycar.com.au/arduino-compatible-photosensitive-ldr-sensor-module/p/XC4446">Photosensor</a> (Duinotech XC4446)</li>
							<li>4x Plastic Boxes (and black paint / tape if they aren't already)</li>
							<li>Assorted Arduino-compatible wires and wire extensions.</li>
						</ul>
						<p>Keep in mind that if you only buy the above, with no displays, you will need to modify the code to output to serial (it mostly does this already, but you'd want to clean it up a bit if it's your main interface).</p>
						<p>If you can't find the same displays, I'd suggest doing this anyway because you'll most likely end up needed to make modifications anyway if you're using a different one, and adding it in later if you so desire.</p>

						<p>Optional Parts:</p>
						<ul>
							<li><a href="https://www.jaycar.com.au/128x128-lcd-screen-module-for-arduino/p/XC4629">LCD display</a> (Duinotech XC4629) - Be careful you don't buy one that uses too many pins.</li>
							<li><a href="https://www.jaycar.com.au/8-digit-7-segment-display-module/p/XC3714">Segment Display</a> (Maxim MAX7219)</li>
							<li><a href="https://www.jaycar.com.au/arduino-compatible-active-buzzer-module/p/XC4424">Buzzer</a> (Duinotech XC4424)</li>
							<li>Gantry Materials: Whatever you can find. Even cardboard or a table's legs with tape will do for a first prototype.</li>
						</ul>
					</div>
				</div>

				<div className="panel panel--is-decorated panel--is-shadow panel--has-border">
					<div className="panel__content">
						<h2>Resources</h2>
						<p>
							<a href="https://www.circuito.io/app?components=512,9088,9088,10168,11021,11441,193621,193621">Wiring Diagram</a>
						</p>
						<p>
							<a href="https://github.com/nikmaly/rc-timer">Code</a>
						</p>
					</div>
				</div>

				<div className="link--return">
					<NavLink exact to={`/projects`}>
						Return
					</NavLink>
				</div>
			</div>
		);
	}
}

export default RcTimer;
