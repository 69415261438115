import React, { Component } from "react";
import PropTypes from "prop-types";
import NavBar from "../structure/NavBar";
import HeroBanner from "../modules/HeroBanner";

class PageWrapper extends Component {
	render() {
		const pagePrefix = " pg-",
			landingTitle = (
				<React.Fragment>
					<div className="landing-text">
						<span className="left-text">Nik</span>
						<span className="left-text">Malyaris</span>
					</div>
				</React.Fragment>
			);

		let isLanding = this.props.title === "Landing" ? true : false,
			type = isLanding ? "landing" : "content",
			title = isLanding ? landingTitle : this.props.title;

		return (
			<section className={`cm-page ${this.props.classes && this.props.classes.map((c) => pagePrefix + c).join('')}`}>
				<NavBar />
				<HeroBanner type={type} title={title} />
				{
					!isLanding && (
						<main className="main" id="main">
							{this.props.children}
						</main>
					)
				}
			</section>
		);
	}
}

PageWrapper.propTypes = {
	classes: PropTypes.array,
	title: PropTypes.string,
	isLanding: PropTypes.bool,
};

PageWrapper.defaultProps = {
	classes: [],
	title: "Nik Malyaris",
	isLanding: false,
};

export default PageWrapper;
