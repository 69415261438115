import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import PageWrapper from "../assets/components/global/PageWrapper";

// ===== Pages
import Landing from "./Landing";
import Projects from "./Projects";
import Resume from "./Resume";

// ===== Projects
import PhotographyPortfolio from "./projects/PhotographyPortfolio";
import PokemonReact from "./projects/PokemonReact";
import Nebula from "./projects/Nebula/Nebula";
import CssCamera from "./projects/CssCamera";
import KeepAway from "./projects/KeepAway/KeepAway";
import Colorand from "./projects/Colorand/Colorand";
import GameOfLife from "./projects/GameOfLife/GameOfLife";
import RcTimer from "./projects/RcTimer";

// Errors
import Error404 from "./errors/404";

class ContentContainer extends Component {
	state = {
		isLoading: true,
		error: null,
		routes: [],
	};

	fetchRoutes() {
		fetch(`https://s3.ap-southeast-2.amazonaws.com/nik.malyaris.com/data-files/routes.json`)
			.then((response) => response.json())
			.then((data) =>
				this.setState({
					routes: data,
					isLoading: false,
				})
			)
			.catch((error) =>
				this.setState({
					error,
					isLoading: false
				})
			);
	}

	componentDidMount() {
		this.fetchRoutes();
	}

	render() {
		if (!this.state.isLoading) {
			return (
				<Switch>
					<Route exact path='/' render={() => <PageWrapper title={'Landing'} classes={['landing']}><Landing/></PageWrapper>} />
					<Route exact path='/resume' render={() => <PageWrapper title={'Resume'} classes={['resume']}><Resume/></PageWrapper>} />
					<Route exact path='/resume-download' render={() => <PageWrapper title={'Resume'} classes={['resume','download']}><Resume download={true}/></PageWrapper>} />
					<Route exact path='/projects' render={() => <PageWrapper title={'Projects'} classes={['projects']}><Projects routes={this.state.routes}/></PageWrapper>} />
					<Route exact path='/projects/photography-portfolio' render={() => <PageWrapper title={'Photography'} classes={['project--photography-portfolio']}><PhotographyPortfolio/></PageWrapper>} />
					<Route exact path='/projects/pokemon-react' render={() => <PageWrapper title={'Pokemon'} classes={['project--pokemon-react']}><PokemonReact/></PageWrapper>} />
					<Route exact path='/projects/nebula' render={() => <PageWrapper title={'Nebula'} classes={['project--nebula']}><Nebula/></PageWrapper>} />
					<Route exact path='/projects/css-camera' render={() => <PageWrapper title={'CSS Camera'} classes={['project--css-camera']}><CssCamera/></PageWrapper>} />
					<Route exact path='/projects/keepaway' render={() => <PageWrapper title={'Keep Away'} classes={['project--keepaway']}><KeepAway/></PageWrapper>} />
					<Route exact path='/projects/colorand' render={() => <PageWrapper title={'Colorand'} classes={['project--colorand']}><Colorand/></PageWrapper>} />
					<Route exact path='/projects/game-of-life' render={() => <PageWrapper title={'Game Of Life'} classes={['project--game-of-life']}><GameOfLife/></PageWrapper>} />
					<Route exact path='/projects/rc-timer' render={() => <PageWrapper title={'RC Timer'} classes={['project--rc-timer']}><RcTimer/></PageWrapper>} />
					{/* <Route exact path="/about" component={About} /> */}
					{/* <Route
							exact
							path='/galleries'
							render={(props) => (
								<GalleryLanding {...props} routes={this.props.data} />
							)}
						/> */}
					{/* <Route
							path="/galleries/:id"
							render={(routerProps) => this.renderGallery(routerProps)}
						/> */}
					<Route component={Error404} />
				</Switch>
			);
		} else {
			return (
				<h1>Loading data...</h1>
			)
		}

	}
}

export default ContentContainer;
