import React from "react";
import { NavLink } from 'react-router-dom';

function Error404() {
	return (
		<div className="pg-error">
			<h1>Error: 404</h1>
			<p>
				This page doesn't seen to exist.
			</p>
			<p>
				Try pressing back or go to the homepage{" "}
				<NavLink exact to='/'>here</NavLink>
			</p>
		</div>
	);
}

export default Error404;
