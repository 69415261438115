import React, { Component } from "react";
class Landing extends Component {

	render() {
		return (
			<React.Fragment></React.Fragment>
		);
	}
}

export default Landing;
