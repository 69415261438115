import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import NebulaCloud from "./NebulaCloud";

class Nebula extends Component {
	componentDidMount() {
		NebulaCloud();
	}

	render() {
		return (
			<div className="page-padding animation--fade">
				<div className="center-text">
					<h1 className="decorated">Nebula</h1>
				</div>

				<div className="link--return link--return--mobile-top">
					<NavLink exact to={`/projects`}>
						Return
					</NavLink>
				</div>

				<div className="panel panel--is-decorated panel--is-shadow panel--has-border">
					<div className="panel__content">
						<p>Nebula is a free streaming ser... no not that nebula (though you <i>should</i> check it out if you haven't).</p>
						<p><b>This</b> Nebula is a simple, yet elegant and realtively performant animated and interactive display. Really it's pupose is as a background, with some limited interactivity with a 'mouse' ball.</p>
					</div>
				</div>

				<div className="panel panel--is-decorated panel--is-shadow panel--has-border">
					<div className="panel__content">
						<div className="nebula-wrapper">
							<canvas
								id="canvasNebula"
								className="canvasNebula"
								style={{backgroundColor: "#111"}}
							>
							</canvas>
						</div>
					</div>
				</div>

				<div className="panel panel--is-decorated panel--is-shadow panel--has-border">
					<div className="panel__content">
						<p>
							For a rather pleasing animation, that contrasts simplicity in design with complexity of motion, the logic powering it is fairly straightforward.
							<br/><br/>
							The balls are simply a large array of 'nebulaBall' objects, which have some standardised properties (color, size, etc) and some dynamic ones (direction (x, y), velocity(x, y)). Each 'ball' is given a random position on the edge of the canvas on load, as well as a random direction and velocity.
							<br/><br/>
							The links are even more simple - if a ball is within the given cutoff distance of another ball, a link is drawn with an opacity proportional to their proximity.
							<br/><br/>
							The mouse is an abstraction on the normal balls - it uses all the same standard properties lives in the same array (meaning it inherits appearance and the link drawing behaviour), but it has no velocity (meaning each frame it doesn't move) it's position is calculated from the mouse position.
						</p>
						<p>
							The result of this is a very simple set of rules which interact with each other to create a beautiful effect. This is one of my favourite things about programming - taking seemingly innocuous properties and combining them to make something amazing.
						</p>
					</div>
				</div>

				<div className="panel panel--is-decorated panel--is-shadow panel--has-border">
					<div className="panel__content">
						<p>
							The plugin has a number of customisable options, including:
						</p>

						<ul>
							<li>Canvas Size<sup>**</sup></li>
							<li>Nebula Size (number of balls) <sup>**</sup></li>
							<li>Nebula Buffer (the unrendered but active area outside the edge of the canvas) <sup>*</sup></li>
							<li>Ball Color</li>
							<li>Ball Size</li>
							<li>Ball Alpha</li>
							<li>Ball Min/Max Velocity<sup>*</sup></li>
							<li>Link Color</li>
							<li>Link Width</li>
							<li>Link Cutoff Distance<sup>**</sup></li>
						</ul>
						<sub>
							Options denoted with a <b><sup>*</sup></b> indicate they impact performance.
							<br/>
							Options denoted with a <b><sup>**</sup></b> indicate a significant performance impact.
						</sub>

						<div className="container container--has-2-items">
							<img src="/assets/images/projects/nebula/site-mockup.png" alt="nebula-page-mockup"></img>
							<p>The above is a mockup of a site I had intended to use this code on. This was planned to be a project and portfolio site before I scrapped the plan and merged it into this one.</p>
						</div>

						<p>
							You can find the code for this <a href="https://github.com/nikmaly/nebula">here at GitHub</a> but I do recommend considering a rewrite if you actually want to use it, this was written more out of curiosity for how fairly simple maths can create very abstract experiences than performant, deployment ready code.
						</p>
					</div>
				</div>

				<div className="link--return">
					<NavLink exact to={`/projects`}>
						Return
					</NavLink>
				</div>
			</div>
		);
	}
}

export default Nebula;
