import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import GameOfLifeScript from "./GameOfLifeScript";

class GameOfLife extends Component {
	componentDidMount() {
		GameOfLifeScript();
	}

	render() {
		return (
			<div className="page-padding animation--fade">
				<div className="center-text">
					<h1 className="decorated">Game of Life</h1>
				</div>
				<div className="link--return link--return--mobile-top">
					<NavLink exact to={`/projects`}>
						Return
					</NavLink>
				</div>

				<div className="panel panel--is-decorated panel--is-shadow panel--has-border">
					<div className="panel__content">
						<p>Game Of Life is a classic, and I made my first version in Uni for a project using C#.</p>
						<p>It's a fascinating concept to me because of how it can make pure code feel like something natural. The other thing that fascinates me is that such a simple set of rules can create a such a complex meta-environment around it.</p>
						<p>As a general description, game of life is a series of very simple rules regarding how the game 'state' changes between iterations. Basically, the rules boiled down to if a cell has two neighbours it lives on, and if it has three neighbours it will either survive or become alive if it wasn't already. Any other situation causes the 'cell' to die.</p>
						<p>With these simple rules, any given state will always 'play out' precisely the same way, but even the slightest variation can (and often will) drastically change how it plays out.</p>
						<p>This game canvas is toroidal - that is behaves as a torus - where the edges 'wrap' around. This is so you don't end up with edge dead zones (by the nature of the rules, borders will unnaturally cause deaths in cells, an unavoidable result of having a relatively small canvas).</p>
					</div>
				</div>

				<div className="wrapper">
					<div className="panel panel--is-decorated panel--is-shadow panel--has-border gol-canvas">
						<canvas id="golCanvas" width="399" height="399"></canvas>
					</div>
					<div className="panel panel--is-decorated panel--is-shadow panel--has-border gol-controls">
						<h2>Controls</h2>
						<button id="btnGolReset">Reset</button>
						<button id="btnGolRandom">Random</button>
						<button id="btnGolCopperhead">Copperhead</button>
						<button id="btnGolHammerhead">Hammerhead</button>
						<button id="btnGolGosper">Gosper's Glider Gun</button>
						<button id="btnPufferfish">Pufferfish</button>
					</div>
				</div>

				<div className="panel panel--is-decorated panel--is-shadow panel--has-border">
					<div className="panel__content">
						<p>The above options, other than the top two which are self-evident in their function, create pre-defined patterns which have unique 5behaviours.</p>
						<p></p>
						<p>A brief overview of the options:</p>
						<ul>
							<li>Copperhead: A fairly basic and slow spaceship (a pattern that moves itself along indefinitely or near-indefinitely without breaking down).</li>
							<li>Hammerhead: A slightly larger, more complex, faster spaceship.</li>
							<li>Gosper's Glider Gun: A pattern which will periodically shoot out 'spaceships' - specifically gliders at an angle.</li>
							<li>Pufferfish: A puffer pattern, which acts like a spaceship, but leaves 'debris' in it wake. This one travels vertically.</li>
						</ul>

						<p>Unlike most of the contrived and uninteresting tasks you're usually given in Uni, something about Game of Life really resonated with me, so I decided to explore it a bit more. I made this mostly to toy with different configurations, and some examples are listed in the controls below.</p>
						<p>The code for this is quite old, and mostly here to demonstrate my personal progression. I've uplifted it at least a little bit, but it's mostly in its original state.</p>
						<p>Click on one of the buttons (other than reset...) to make it do something more interesting.</p>
					</div>
				</div>

				<div className="link--return">
					<NavLink exact to={`/projects`}>
						Return
					</NavLink>
				</div>
			</div>
		);
	}
}

export default GameOfLife;
