import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import RoutesController from "./RoutesController";

class App extends Component {
	render() {
		return (
			<BrowserRouter>
				<RoutesController />
			</BrowserRouter>
		);
	}
}

export default App;
