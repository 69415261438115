import React, { Component } from "react";

class IconTile extends Component {
	render() {
		return (
			<div className="icon-tile">
				<div className={`icon-tile--icon icon-tile--icon--${this.props.color}`}>
					<img src={this.props.resource} alt={this.props.altText} />
				</div>

				{
					this.props.children && (
						<div className="icon-tile--content">
							{this.props.children}
						</div>
					)
				}
			</div>
		);
	}
}

IconTile.defaultProps = {
	resource: "",
	altText: "",
	color: "primary",
};

export default IconTile;
