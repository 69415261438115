import React, { Component } from "react";
import { NavLink } from 'react-router-dom';

class Projects extends Component {
	generateLinks() {
		let routes = this.props.routes;

		return Object.keys(routes).map(key => {
			return (
				<div className="route-path" key={key}>
					{/* <h1 className="decorated">{key}</h1> */}
					<ul>
						{
							Object.keys(routes[key]).map((index) => {
								let route = routes[key][index];

								return (
									<li key={index}>
										<NavLink exact to={`${key}/${route.id}`}>
											{route.name}
										</NavLink>
									</li>
								);
							})
						}
					</ul>
				</div>
			)
		})
	}

	render() {
		return (
			<React.Fragment>
				<div className="page-padding">
					{/* LINKS */}
					<div className="feature-links">
						{this.generateLinks()}
					</div>

					{/* PROJECT CONTAINER */}
				</div>
			</React.Fragment>
		);
	}
}

export default Projects;
