import React, { Component } from "react";
import { NavLink } from 'react-router-dom';

class PhotographyPortfolio extends Component {
	render() {
		return (
			<div className="page-padding animation--fade">
				<div className="center-text">
					<h1 className="decorated">Photography Portfolio</h1>
				</div>

				<div className="link--return link--return--mobile-top">
					<NavLink exact to={`/projects`}>
						Return
					</NavLink>
				</div>

				<div className="panel panel--is-decorated panel--is-shadow panel--has-border">
					<div className="panel__content">
						<h2>Intro</h2>

						<p>Like this site, the original iteration of my photogrpahy portfolio was created to teach myself end to end web design. The first version of both these sites date back before Uni, when I was starting to learn skills I felt I ought to know.</p>
						<p>Since then, both have evolved. This site has turned into my professional online resume and portfolio, whilst my photography site is slowly moving to a complete professional photography portfolio and prrint sales site.</p>
						<p>I've been through dozens of iterrations, usually when I've found a new technique or style that I want to try out. Over time it's settled towards various variations on a minimal logo screen fading to a simple banner.</p>
					</div>
				</div>

				<div className="panel panel--is-decorated panel--is-shadow panel--has-border">
					<div className="panel__content">
						<h2>Site</h2>

						<div className="center-text">
							<img src="/assets/images/projects/photography/photography-portfolio.png" alt="portfolio-mockup"></img>
							<p>Link: <a href="https://photography.malyaris.com">photography.malyaris.com</a></p>
						</div>
					</div>
				</div>

				<div className="panel panel--is-decorated panel--is-shadow panel--has-border">
					<div className="panel__content">
						<h2>Design</h2>

						<p>
							Despite my penchant for reworking or totally redoing the landing page on teh regular, the key to the site is the gallery automation.
						</p>
						<p>
							Early versions of this site, written in php, built out gallery pages and the index based entirely on the gallery folder and what sub-folders were contained.
						</p>
						<p>
							Since then, I've migrated the site several times, and currently it's a React implementation, and builds out the galleries using data files stored in AWS S3.
						</p>
						<p>
							The key feature of the site is that there is zero custom gallery code, just a single gallery factory which builds them out from the S3 data files. This includes everything from the app routes, to the gallery images to the image data and meta data.
						</p>
						<p>
							This is the critical aspect to the site, as it allows me to easily update the site without needing to modify any code at all.
						</p>
					</div>
				</div>

				<div className="panel panel--is-decorated panel--is-shadow panel--has-border">
					<div className="panel__content">
						<h2>Creating new Galleries</h2>

						<p>
							Currently, the deployment process for a new gallery is as follows:
						</p>

						<ul>
							<li>Edit the photos, and curate data files for each</li>
							<li>Upload photos and data files to the S3 bucket</li>
							<li>Create a gallery data file with the gallery info, and links to the image data files in the gallery</li>
							<li>Update the routes data file, again, in the S3 bucket</li>
						</ul>

						<p>
							Not a single step there requires hands on code, and whilst it's still more technical than a typical user would be comfortable with, it wouldn't require too much effort to create an interface hooked up to the AWS CLI to make this process easier.
						</p>
					</div>
				</div>

				<div className="link--return">
					<NavLink exact to={`/projects`}>
						Return
					</NavLink>
				</div>
			</div>
		);
	}
}

export default PhotographyPortfolio;
