import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class NavBar extends Component {
	constructor() {
		super();
		this.state = {
			isMenuOpen: false,
			isScrolled: false,
			headerOffset: 0,
		};
		this.handleScroll = this.handleScroll.bind(this);
	}

	componentDidMount() {
		this.banner = document.querySelector('.hero-banner');
		this.floatingHeader = document.getElementById("floating-navbar");

		if (this.floatingHeader) {
			this.setState({
				headerOffset: this.floatingHeader.offsetTop,
				bannerOffset: this.banner.offsetHeight || 184
			});

			window.addEventListener("scroll", this.handleScroll);
			// TODO: update offset on resize
		}
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
	}

	handleScroll() {
		// let scrolled = window.pageYOffset > (window.innerHeight - 50) * 0.1;
		let scrolled = window.pageYOffset > this.state.bannerOffset;

		if (scrolled) {
			if (!this.state.isScrolled) {
				this.setState({ isScrolled: true });
			}
		} else {
			if (this.state.isScrolled) {
				this.setState({ isScrolled: false });
			}
		}
	}

	handleMenuBtnClick() {
		this.setState({isMenuOpen: !this.state.isMenuOpen})
	}

	handleNavigation() {
		this.setState({isMenuOpen: false})
	}

	render() {
		const navLinks = (
			<ul className="navbar--links">
				<li>
					<NavLink exact to="/" onClick={() => this.handleNavigation()}>
						Home
					</NavLink>
				</li>
				<li>
					<NavLink exact to="/projects" onClick={() => this.handleNavigation()}>
						Projects
					</NavLink>
				</li>
				<li>
					<NavLink exact to="/resume" onClick={() => this.handleNavigation()}>
						Resume
					</NavLink>
				</li>
				<li>
					<a href="https://photography.malyaris.com">
						Photography
					</a>
				</li>
			</ul>
		);

		return (
			<nav
				className={"navbar" + (this.state.isScrolled ? " navbar--is-scrolled" : "")}
				id="floating-navbar"
			>
				<div className="navbar--logo">
					<a href="https://nik.malyaris.com">Home</a>
				</div>

				{/* DESKTOP LINKS */}
				{navLinks}

				<div className="navbar--menu">
					<div className={`navbar--menu__mobile ${
						this.state.isMenuOpen ? ' open': ''
					}`}>
						{/* MOBILE LINKS */}
						{navLinks}
					</div>

					<button
						className={`navbar--menu__button ${this.state.isMenuOpen ? ' open': 'closed'}`}
						onClick={() => this.handleMenuBtnClick()}
					>
						<span className="navbar--menu__button-top"></span>
						<span className="navbar--menu__button-bottom"></span>
					</button>
				</div>
			</nav>
		);
	}
}

NavBar.defaultProps = {};

export default NavBar;
