import React, { Component } from "react";
import { NavLink } from 'react-router-dom';

class PokemonReact extends Component {
	render() {
		return (
			<div className="page-padding animation--fade">
				<div className="center-text">
					<h1 className="decorated">Pokemon React Version</h1>
				</div>

				<div className="link--return link--return--mobile-top">
					<NavLink exact to={`/projects`}>
						Return
					</NavLink>
				</div>

				<div className="panel panel--is-decorated panel--is-shadow panel--has-border">
					<div className="panel__content">
						<h2>Intro</h2>

						<p>I decided at one point that my knowledge of React wasn't good enough. Anyone can potter through making a React app given ultimately you can distill it down into a SPA equivalent of a website. My goal was to make sure I acquired a much better understanding of the underlying architecture of React.</p>
						<p>I've always been amazed that a resource like the PokeAPI exists, the level of detail is almost horrifying when you consider the time and effort investment, and I've wanted to use it in some capacity for some time.</p>
					</div>
				</div>

				<div className="panel panel--is-decorated panel--is-shadow panel--has-border">
					<div className="panel__content">
						<h2>Site</h2>

						<div className="center-text">
							<img src="/assets/images/projects/pokemon/pokemon-site.png" alt="portfolio-mockup"></img>
							<p>Link: <a href="https://pokemon.malyaris.com">pokemon.malyaris.com</a></p>
						</div>
					</div>
				</div>

				<div className="panel panel--is-decorated panel--is-shadow panel--has-border">
					<div className="panel__content">
						<h2>Implementation</h2>

						<ul>
							<li>React (functional components and a useContext global state)</li>
							<li>Emotion</li>
							<li>PokeAPI</li>
						</ul>

						<p>Now to be clear, React is NOT the right choice for this, or at least not the way I've done it. Certainly using it purely as a view library would be fine, but one of the key tennets of a game environment is that it's state is user input dependant. That makes the user's input the source of truth, not the state itself, which means you end up trying to keep the state updated to match user input (which isn't really meant to be a synchronous affair), and end up in callback hell very quickly.</p>
						<p>I knew that going into this - it's not a purely React app (it's a bit of a vanilla classes and functional React hybrid), but really the point of this was to run head first into every problem possible because it would force me to really understand why thigns were breaking (with the full realisation that a lot of things would be impossible to solve properly).</p>
						<p>Emotion was just because I don't like CSS in JS, but the prevailing winds of FED seem pretty clearly set in that direction. I don't like it, but that just means I need to work harder to make sure I can at least work with it. I'm running a very low tech implementation indeed at the moment because I'm trying to focus on functionality, but that will change (along with moving to typescript).</p>
					</div>
				</div>

				<div className="panel panel--is-decorated panel--is-shadow panel--has-border">
					<div className="panel__content">
						<h2>Design</h2>

						<p>Technically, the goal was as data driven as possible. All the input data, game state, even the events and machinations are all data driven and built to be as reusable as possible.</p>
						<p>The goal originally was to use the poke API directly, but at least the initial data has to be cached (there were too many requests too quickly and it was causing the API to start rejecting connections). Long term game state is stored in local storage, and is 'saved' each time the context provider fires the relevant reducer to update the state. It means I have to be careful not to have too many updates or too large of a game state (flags in this case) object, but sometimes simple is best.</p>
						<p>The design was a mix of what I could build quickly to enable the funcitonal side of the code as a simplified and more 'web-appy' take on the familiar pokemon interfaces.</p>

					</div>
				</div>

				<div className="link--return">
					<NavLink exact to={`/projects`}>
						Return
					</NavLink>
				</div>
			</div>
		);
	}
}

export default PokemonReact;
