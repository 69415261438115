import React, { Component } from "react";

class Resume extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="resume-wrapper">
					{/* Intro Panel */}
					<section className="panel panel-profile">
						<div className="panel--partition snapshot">
							<img width="140px" src="assets/images/profile.jpg" alt="me"></img>
							<h1>Nik Malyaris</h1>
							<p>Web Developer</p>
						</div>
						<div className="panel--partition highlights">
							<ul>
								<li><span className="icon icon--website"></span><a href="https://nik.malyaris.com">https://nik.malyaris.com</a></li>
								<li><span className="icon icon--email"></span>nik@malyaris.com</li>
								<li><span className="icon icon--globe"></span>Melbourne, Australia</li>
								<li><span className="icon icon--person"></span>Front End Web Development</li>
								<li><span className="icon icon--focus"></span>UI Development & Best Practice</li>
								<li><span className="icon icon--languages"></span>HTML5, CSS & SASS, JS, React, PHP</li>
							</ul>
							{
								/*
									https://webpagetopdf.com/
									https://cloudconvert.com/save-website-pdf
								*/
								!this.props.download && (
									<a href="assets/nik-malyaris-resume.pdf" className="cta cta-grey cta-download" download>
										Download as PDF
										<span className="icon icon--download icon--right"></span>
									</a>
								)
							}
						</div>

					</section>

					{/* Main Content Panel */}
					<section className="panel panel-details">
						<div className="panel--partition bio">
							<h2>about me.</h2>
							<p>
								I am a front-end web developer, with a focus on transforming the business needs of my clients into a technical implementation.
							</p>
							<p>
								My passion is helping clients achieve their vision, providing a full service offering ranging from requirements analysis and design, all the way through the journey to implementation and long term management and support.
							</p>
							<p>
								I believe that technology is only a tiny part of a long process - I love the power and reach websites provide, but what drives me is the translation of business into technical, and helping the two worlds meet and build experiences that neither could on their own. There's no solution without technical, and no goal without business; marrying these up is my driving mission.
							</p>
							<p>
								I strive for the highest quality and maintainability of my code and solutions through an exacting approach to my work.
								Making a real difference to my clients and driving positive change in the tech industry are core to my values, and this drives me to improve my capabilities.
								I have a strong focus on both development best practices, as well as inclusive & accessible design. I feel very strongly that we have an obligation to ensure the web is available to everyone, and that accessibility isn't just a checkbox to tick off, but a responsibility.
							</p>
							<p>
								In my spare time, I'm an avid chef, photographer and tech nerd. Remote control is my specialty, and I've spent a lot of time behind the controls of my cars and drones, as well as working on projects to find new ways to interact with them.
							</p>
						</div>
						<div className="panel--partition experience">
							<h2>experience.</h2>

							<div className="split-partition feature-right">
								<div className="left-container">
									<h3>Deloitte</h3>
									<p><i>Digital - Assets & Managed Services</i></p>
									<p>Front End Tech Lead</p>
									<p>November 2018 - Present</p>
								</div>
								<div className="right-container">
									<h4>Role Description:</h4>
									<p>
										Assets & Managed Services is a full service managed services offering, in which I've supported everything from simple single page apps, to complex multinational multi-site solutions.
										The majority of my experience is with clients in the financial sector, however I've also worked across logistics & freight, retail and government.
									</p>
									<p>
										My responsibilities initially were basic code development, primarily working on existing codebases for bug resolution and minor enhancements.
										This position has grown into a Managed Services wide Front End Tech Lead role, supporting a wide variety of clients as well as mentoring other developers.
									</p>
									<p>
										All of my work revolves around a core value of delivering excellence to meet our clients' goals.
										One of the key aspects of this goal is to provide a more proactive approach to helping our clients with their business needs.
										Traditionally managed services is a reactionary service, but by focusing on individual client relationships - building trust and a strong rapport to understand their business and needs properly - I am trying to move the FED space into having a proactive role in supporting these clients and making a real difference.
									</p>
									<h4>Skills:</h4>
									<ul>
										<li>UX/UI Advisory and Site Optimisation</li>
										<li>Solution Design</li>
										<li>Complex Code Development</li>
										<li>Long Term Solution Maintenance & Enhancements</li>
										<li>Adaptability & Quick to Upskill</li>
										<li>Client and Stakeholder Management</li>
										<li>Team Leadership</li>
									</ul>
								</div>
							</div>

							<div className="split-partition feature-right">
								<div className="left-container">
									<h3>Able Australia</h3>
									<p><i>Quality and Risk</i></p>
									<p>Process Systems Developer</p>
									<p>Jan 2018 - March 2018</p>
								</div>
								<div className="right-container">
									<h4>Role Description:</h4>
									<p>
										Continuing work done previously as part of a Swinburne University Industry Capstone Project, I worked as a Business Process Developer within Able Australia's Quality and Risk Team.
										The goal of the program was to help Able Australia manage and optimise the wide variety of previously undocumented processes across the business.
									</p>
									<p>
										The role required interpersonal communication with staff at all levels including senior department leads, with stakeholder and change management being key areas I focused on to accomplish a successful outcome.
									</p>
									<h4>Skills:</h4>
									<ul>
										<li>Information Analysis</li>
										<li>Framework Prototyping and Rollout</li>
										<li>Independent Project Management</li>
										<li>Communication</li>
									</ul>
								</div>
							</div>

							<div className="split-partition feature-right">
								<div className="left-container">
									<h3>PricewaterhouseCoopers</h3>
									<p><i>Cyber</i></p>
									<p>Internship</p>
									<p>Feb 2017 - July 2017</p>
								</div>
								<div className="right-container">
									<h4>Role Description:</h4>
									<p>
										I worked on a series of projects within the Cyber area, including analysis for a major financial institution, forensic kit auditing and administration and worked with experts to upskill myself in penetrations testing & auditing.
									</p>
									<p>
										The work included isolated situations requiring self-motivation and management, to working directly with senior client representatives.
										Most of the work was in areas beyond my knowledge base, and I was required to quickly learn specific and in-depth information on a variety of topics as well as be highly adaptable to daily changes.
									</p>
									<h4>Skills:</h4>
									<ul>
										<li>Financial Analysis</li>
										<li>Document Auditing</li>
										<li>Self-Motivated and Managed Work</li>
										<li>Client Interaction and Management</li>
									</ul>
								</div>
							</div>

							<div className="split-partition feature-right">
								<div className="left-container">
									<h3>Deloitte</h3>
									<p><i>Digital</i></p>
									<p>Internship</p>
									<p>July 2016 - November 2016</p>
								</div>
								<div className="right-container">
									<h4>Role Description:</h4>
									<p>
										I worked on a number of projects requiring various skills. These included back-end development & testing, front-end development, framework research & creation and UX Design & Business Analysis.
										The majority of my time was spent working on new frameworks; either their application to new engagements, or the development and documentation of a new mobile-centric framework for use in the future.
									</p>
									<p>
										I worked with a number of different teams through this, and the ability to integrate quickly and work effectively in teams I was not familiar with was essential.
									</p>
									<h4>Skills:</h4>
									<ul>
										<li>Software Development (Web & Mobile)</li>
										<li>Self-Driven & Organised Upskilling</li>
										<li>Adaptability</li>
										<li>Teamwork and Communication Skills</li>
									</ul>
								</div>
							</div>

							<div className="split-partition feature-right">
								<div className="left-container">
									<h3>Freelance</h3>
									<p>October 2015 - Ongoing</p>
								</div>
								<div className="right-container">
									<h4>Role Description:</h4>
									<p>
										I've worked with Mentone Grammar on a number of occasions, included creation of videos for students, parents and the general public, indexing of student recordings, and third party presentation assistance.
									</p>
									<p>
										Since this initial work, I've worked with a number of small clients to help them improve their online presence through boutique websites & full managed service packages, including new features/content updates, deployments and hosting.
									</p>
									<h4>Skills:</h4>
									<ul>
										<li>Requirements Analysis</li>
										<li>Client Management</li>
										<li>Video Editing / Graphics Creation</li>
										<li>Devops & Infrastructure Management</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="panel--partition education">
							<h2>education.</h2>

							<div className="split-partition feature-right">
								<div className="left-container">
									<h4>Tertiary</h4>
									<p>2015 - 2017</p>
								</div>
								<div className="right-container">
									<p><i>Bachelor of Information Technology</i></p>
									<p>Distinction Average</p>
									<p>Swinburne University</p>
								</div>
							</div>

							<div className="split-partition feature-right">
								<div className="left-container">
									<h4>Secondary</h4>
									<p>2009 - 2014</p>
								</div>
								<div className="right-container">
									<p><i>Victorian Certificate of Education</i></p>
									<p>Mentone Grammar School</p>
								</div>
							</div>

							<div className="split-partition feature-right">
								<div className="left-container">
									<h4>Other Qualifications</h4>
								</div>
								<div className="right-container">
									<p><i>AWS Certified Cloud Practitioner</i></p>
								</div>
							</div>
						</div>

						<div className="panel--partition referees">
							<h2>references.</h2>
							<p>Available on request.</p>
						</div>

						<div className="panel--partition contact">
							<h2>contact.</h2>
							<p>Email: nik@malyaris.com</p>
						</div>
					</section>
				</div>
			</React.Fragment>
		);
	}
}

export default Resume;
