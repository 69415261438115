import React, { Component } from "react";
import { NavLink } from 'react-router-dom';

class CssCamera extends Component {
	state = {screenOpen: false,}

	takePhoto = () => {
	  this.setState({buttonPressed: !this.state.buttonPressed});
	}

	render() {
		return (
			<div className="page-padding animation--fade">
				<div className="center-text">
					<h1 className="decorated">Css Camera</h1>
				</div>
				<div className="link--return link--return--mobile-top">
					<NavLink exact to={`/projects`}>
						Return
					</NavLink>
				</div>

				<div className="panel panel--is-decorated panel--is-shadow panel--has-border">
					<div className="panel__content">
						<p>CssCamera was just a fun little project of taking CSS a bit to far.</p>
						<p>There's no good reason to do it this way, but it was fun, and I'm a fan of the real-world material object design which you are forced into with this style.</p>
						<p>I had hopes for finding a use for it - that's where the idea behind making the shutter release functional and a link to my photofraphy portfolio - but honestly it's more than anything a case of making something for the sake of it. And hey, useful for slipping into a portfolio. It's also rather interesting to see how I've developed - and how I'd never even consider using the approach I took when I wrote this today!</p>
						<p>Unfortunately I never really wrote this for mobile use, so it's not responsive. If I ever find a use for it I'll convert it, but otherwise you'll just have to deal with horizontal scrolling to see it all!</p>

						<p>
							Try clicking the button on the left of the camera!
							<br/>
							<sup>(clicking again resets)</sup>
						</p>

						<p>
							The code for this can be found at <a href="https://github.com/nikmaly/css-camera">Github</a>.
						</p>
					</div>
				</div>

				<div className={"camera-wrapper" + ( this.state.buttonPressed ? " screen-open" : "" )}>

                    <div className="camera-body">
                      <div className="camera-stripe"></div>

                      <div className="camera-strip">
                        <div className={"camera-flash" + ( this.state.buttonPressed ? " flashing" : "" )}></div>
                      </div>

                      <div className="camera-lens">
                        <div className="lens-insets">
                          <div className="lens-inset-1">
                            <div className="lens-inset-2">
                              <div className="lens-inset-3">
                                <div className="lens-inset-4">
                                  <div className="lens-inset-5">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="camera-grip">
                        <div className={"camera-button" + ( this.state.buttonPressed ? " pressed" : "" )} onClick={this.takePhoto}>
                        </div>
                      </div>

                    </div>

                    <div className={"camera-screen" + ( this.state.buttonPressed ? " flipped" : "" )}>
                      <div className="screen-hinge"></div>
                        <a href="https://photography.malyaris.com">
                      		<div className="screen-display"></div>
						</a>
                    </div>

                </div>

				<div className="link--return">
					<NavLink exact to={`/projects`}>
						Return
					</NavLink>
				</div>
			</div>
		);
	}
}

export default CssCamera;
