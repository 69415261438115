import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import ColorandScript from "./ColorandScript";

class Colorand extends Component {
	componentDidMount() {
		ColorandScript();
	}

	render() {
		return (
			<span className="animation--fade">
				<section id="cr-canvas" className="cr-canvas">
					<div className="canvas-wrapper">
						<div id="cr-code" className="output-code">#FFFFFF</div>

						<button id='cr-copy-button' className="copy-button"></button>
						<div id='cr-copy-alert' className="copy-alert">Copied to clipboard!</div>

						<div id='cr-intro' className="intro-text">
							<span className="mobile">Click to Start</span>
							<span className="desktop">Press Space to Start</span>
						</div>
					</div>
				</section>

				<div className="page-padding">
					<div className="center-text">
						<h1 className="decorated">Colorand</h1>
					</div>

					<div className="link--return link--return--mobile-top">
						<NavLink exact to={`/projects`}>
							Return
						</NavLink>
					</div>


					<div className="panel panel--is-decorated panel--is-shadow panel--has-border">
						<div className="panel__content">
							<p>After naming variables, picking colors - when you don't have a designer to do it for you - is the single hardest thing about web development.</p>
							<p>So why not make a mini app that does it for you?</p>
							<p>Honestly there's not a lot more to say about this. I made this a long time ago, so it's not particularly well build. Hit space (or hold it if you want to test if you have epilepsy), get given a new color.</p>
							<p>The only interesting thing on it is a bit of code that detects the luminosity of the color (which is set to the background) to choose either black or white text colors so it's more easily readable.</p>
							<p>
								The code for this can be found at <a href="https://github.com/nikmaly/colorand">Github</a>.
							</p>
						</div>
					</div>

					<div className="link--return">
						<NavLink exact to={`/projects`}>
							Return
						</NavLink>
					</div>
				</div>
			</span>
		);
	}
}

export default Colorand;
