import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import KeepAwayInit from "./KeepAwayCode";

class KeepAway extends Component {
	componentDidMount() {
		KeepAwayInit();
	}

	render() {
		return (
			<div className="page-padding animation--fade">
				<div className="center-text">
					<h1 className="decorated">KeepAway</h1>
				</div>
				<div className="link--return link--return--mobile-top">
					<NavLink exact to={`/projects`}>
						Return
					</NavLink>
				</div>

				<div className="panel panel--is-decorated panel--is-shadow panel--has-border">
					<div className="panel__content">
						<p>KeepAway is a simple little game (game is probably a strong word - interactive animation would be closer) of keep away with the computer. </p>
						<p>The concept of keep away is pretty straightforward - you chase something, it runs away. In this case you hover over the target, and it moves.</p>

						<div className="keepaway-score">
							Score: <span id="keepaway-score">0</span>
						</div>

						<div style={{width: '54px', height: '111px', zIndex: 99}}>
							<div className="keepaway keepaway-initial" id="keepaway-target" style={{position: 'fixed', width: '54px', height: '111px'}}></div>
						</div>
					</div>
				</div>

				<p>
					It was originally built as something to irritate a friend (the 'target' area was much larger than the visual target, so you could never actually catch it).
					If that sounds crude, it's nothing on the fact that the original implementation of that 'larger' target area was just an image where the target was about 50% the size of the PNG canvas in the centre.
				</p>

				<p>
					The target animates based on scroll, with both static and alternative directional states.
					<br/>
					This page has been artifically enlarged to enforce a scroll state to demonstrate this functionality, but will not affect the target's positioning.
					<br/>
					There was an intention to eventually look at animating its general movement (e.g. on 'catch'), but the pixel art I did for it would have looked rather jarring without a lot of work around transitions, so I never did get around to finishing it (it was after all written as an amusement piece).
				</p>

				<p>
					The code for this and sprites for this specific model can be found at <a href="https://github.com/nikmaly/keepaway">Github</a>.
				</p>

				<div className="link--return">
					<NavLink exact to={`/projects`}>
						Return
					</NavLink>
				</div>
			</div>
		);
	}
}

export default KeepAway;
