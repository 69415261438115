var gridHeight,
	gridWidth,
	liveArray,
	tempArray,
	c,
	ctx;

// =============================== GAME FUNCTIONS ===============================
// main loop control
function tick() {
	drawGrid();
	updateGrid();
	window.requestAnimationFrame(tick);
}

// creates a 2 dimensional array of required height
function createArray(rows) {
	var arr = [];

	for (var i = 0; i < rows; i++) {
		arr[i] = [];
	}

	return arr;
}

// draw the contents of the grid onto a canvas
function drawGrid() {
	ctx.clearRect(0, 0, gridHeight, gridWidth); //this should clear the canvas ahead of each redraw

	for (var j = 1; j < gridHeight; j++) { //iterate through rows
		for (var k = 1; k < gridWidth; k++) { //iterate through columns
			if (liveArray[j][k] === 1) {
				ctx.fillRect(j, k, 1, 1);
			}
		}
	}
}

//perform one iteration of grid update
function updateGrid() {
	var temp;

	for (var j = 1; j < gridHeight - 1; j++) { //iterate through rows
		for (var k = 1; k < gridWidth - 1; k++) { //iterate through columns
			var totalCells = 0;

			//add up the total values for the surrounding cells
			totalCells += liveArray[j - 1][k - 1]; //top left
			totalCells += liveArray[j - 1][k]; //top center
			totalCells += liveArray[j - 1][k + 1]; //top right

			totalCells += liveArray[j][k - 1]; //middle left
			totalCells += liveArray[j][k + 1]; //middle right

			totalCells += liveArray[j + 1][k - 1]; //bottom left
			totalCells += liveArray[j + 1][k]; //bottom center
			totalCells += liveArray[j + 1][k + 1]; //bottom right

			//apply the rules to each cell
			switch (totalCells) {
				case 2:
					tempArray[j][k] = liveArray[j][k];

					break;
				case 3:
					tempArray[j][k] = 1; // live

					break;
				default:
					tempArray[j][k] = 0;
			}
		}
	}

	//mirror edges to create wrap-around effect
	for (var l = 1; l < gridHeight - 1; l++) { //iterate through rows
		//top and bottom
		tempArray[l][0] = tempArray[l][gridHeight - 3];
		tempArray[l][gridHeight - 2] = tempArray[l][1];
		//left and right
		tempArray[0][l] = tempArray[gridHeight - 3][l];
		tempArray[gridHeight - 2][l] = tempArray[1][l];

	}

	//swap grids
	temp = liveArray;
	liveArray = tempArray;
	tempArray = temp;
}

// Positioner function - parsing in offset from centre returns absolute value
function offsetFinder(offset, dir) {
	if (dir === "col") {
		return (gridWidth / 2) + offset;
	} else if (dir === "row") {
		return (gridHeight / 2) + offset;
	}
}

function reset() {
	for (var j = 0; j < gridHeight; j++) {
		for (var k = 0; k < gridWidth; k++) {
			liveArray[j][k] = 0;
		}
	}
}

// =============================== PRESET LAYOUTS ===============================
// These have to generated manually because we're manually programming in starting 'states'
// There's probably a way of doing this cleverly, but that's well beyond the scope of this mini-project

// fill the grid randomly
function fillRandom() {
	for (var j = 0; j < gridHeight - 0; j++) { //iterate through rows
		for (var k = 0; k < gridWidth - 0; k++) { //iterate through columns
			liveArray[j][k] = Math.round(Math.random());
		}
	}
}

function makeCopperhead() {
	reset();

	// [COL][ROW]
	liveArray[offsetFinder(-2, "col")][offsetFinder(-6, "row")] = 1;
	liveArray[offsetFinder(-1, "col")][offsetFinder(-6, "row")] = 1;
	liveArray[offsetFinder(2, "col")][offsetFinder(-6, "row")] = 1;
	liveArray[offsetFinder(3, "col")][offsetFinder(-6, "row")] = 1;

	liveArray[offsetFinder(0, "col")][offsetFinder(-5, "row")] = 1;
	liveArray[offsetFinder(1, "col")][offsetFinder(-5, "row")] = 1;

	liveArray[offsetFinder(0, "col")][offsetFinder(-4, "row")] = 1;
	liveArray[offsetFinder(1, "col")][offsetFinder(-4, "row")] = 1;

	liveArray[offsetFinder(-3, "col")][offsetFinder(-3, "row")] = 1;
	liveArray[offsetFinder(-1, "col")][offsetFinder(-3, "row")] = 1;
	liveArray[offsetFinder(2, "col")][offsetFinder(-3, "row")] = 1;
	liveArray[offsetFinder(4, "col")][offsetFinder(-3, "row")] = 1;

	liveArray[offsetFinder(-3, "col")][offsetFinder(-2, "row")] = 1;
	liveArray[offsetFinder(4, "col")][offsetFinder(-2, "row")] = 1;

	liveArray[offsetFinder(-3, "col")][offsetFinder(0, "row")] = 1;
	liveArray[offsetFinder(4, "col")][offsetFinder(0, "row")] = 1;

	liveArray[offsetFinder(-2, "col")][offsetFinder(1, "row")] = 1;
	liveArray[offsetFinder(-1, "col")][offsetFinder(1, "row")] = 1;
	liveArray[offsetFinder(2, "col")][offsetFinder(1, "row")] = 1;
	liveArray[offsetFinder(3, "col")][offsetFinder(1, "row")] = 1;

	liveArray[offsetFinder(0, "col")][offsetFinder(2, "row")] = 1;
	liveArray[offsetFinder(-1, "col")][offsetFinder(2, "row")] = 1;
	liveArray[offsetFinder(2, "col")][offsetFinder(2, "row")] = 1;
	liveArray[offsetFinder(1, "col")][offsetFinder(2, "row")] = 1;

	liveArray[offsetFinder(0, "col")][offsetFinder(4, "row")] = 1;
	liveArray[offsetFinder(1, "col")][offsetFinder(4, "row")] = 1;

	liveArray[offsetFinder(0, "col")][offsetFinder(5, "row")] = 1;
	liveArray[offsetFinder(1, "col")][offsetFinder(5, "row")] = 1;
}

function makeHammerhead() {
	reset();

	//     [COL][ROW]
	liveArray[offsetFinder(-9, "col")][offsetFinder(-7, "row")] = 1;
	liveArray[offsetFinder(-8, "col")][offsetFinder(-7, "row")] = 1;
	liveArray[offsetFinder(-7, "col")][offsetFinder(-7, "row")] = 1;
	liveArray[offsetFinder(-6, "col")][offsetFinder(-7, "row")] = 1;
	liveArray[offsetFinder(-5, "col")][offsetFinder(-7, "row")] = 1;

	liveArray[offsetFinder(-9, "col")][offsetFinder(-6, "row")] = 1;
	liveArray[offsetFinder(-4, "col")][offsetFinder(-6, "row")] = 1;
	liveArray[offsetFinder(4, "col")][offsetFinder(-6, "row")] = 1;
	liveArray[offsetFinder(5, "col")][offsetFinder(-6, "row")] = 1;

	liveArray[offsetFinder(-9, "col")][offsetFinder(-5, "row")] = 1;
	liveArray[offsetFinder(3, "col")][offsetFinder(-5, "row")] = 1;
	liveArray[offsetFinder(4, "col")][offsetFinder(-5, "row")] = 1;
	liveArray[offsetFinder(6, "col")][offsetFinder(-5, "row")] = 1;
	liveArray[offsetFinder(7, "col")][offsetFinder(-5, "row")] = 1;
	liveArray[offsetFinder(8, "col")][offsetFinder(-5, "row")] = 1;

	liveArray[offsetFinder(-8, "col")][offsetFinder(-4, "row")] = 1;
	liveArray[offsetFinder(2, "col")][offsetFinder(-4, "row")] = 1;
	liveArray[offsetFinder(3, "col")][offsetFinder(-4, "row")] = 1;
	liveArray[offsetFinder(5, "col")][offsetFinder(-4, "row")] = 1;
	liveArray[offsetFinder(6, "col")][offsetFinder(-4, "row")] = 1;
	liveArray[offsetFinder(7, "col")][offsetFinder(-4, "row")] = 1;
	liveArray[offsetFinder(8, "col")][offsetFinder(-4, "row")] = 1;

	liveArray[offsetFinder(-6, "col")][offsetFinder(-3, "row")] = 1;
	liveArray[offsetFinder(-5, "col")][offsetFinder(-3, "row")] = 1;
	liveArray[offsetFinder(-1, "col")][offsetFinder(-3, "row")] = 1;
	liveArray[offsetFinder(0, "col")][offsetFinder(-3, "row")] = 1;
	liveArray[offsetFinder(2, "col")][offsetFinder(-3, "row")] = 1;
	liveArray[offsetFinder(3, "col")][offsetFinder(-3, "row")] = 1;
	liveArray[offsetFinder(6, "col")][offsetFinder(-3, "row")] = 1;
	liveArray[offsetFinder(7, "col")][offsetFinder(-3, "row")] = 1;

	liveArray[offsetFinder(-4, "col")][offsetFinder(-2, "row")] = 1;
	liveArray[offsetFinder(1, "col")][offsetFinder(-2, "row")] = 1;
	liveArray[offsetFinder(4, "col")][offsetFinder(-2, "row")] = 1;

	liveArray[offsetFinder(-3, "col")][offsetFinder(-1, "row")] = 1;
	liveArray[offsetFinder(-1, "col")][offsetFinder(-1, "row")] = 1;
	liveArray[offsetFinder(1, "col")][offsetFinder(-1, "row")] = 1;
	liveArray[offsetFinder(3, "col")][offsetFinder(-1, "row")] = 1;

	liveArray[offsetFinder(-2, "col")][offsetFinder(0, "row")] = 1;

	liveArray[offsetFinder(-2, "col")][offsetFinder(1, "row")] = 1;

	liveArray[offsetFinder(-3, "col")][offsetFinder(2, "row")] = 1;
	liveArray[offsetFinder(-1, "col")][offsetFinder(2, "row")] = 1;
	liveArray[offsetFinder(1, "col")][offsetFinder(2, "row")] = 1;
	liveArray[offsetFinder(3, "col")][offsetFinder(2, "row")] = 1;

	liveArray[offsetFinder(-4, "col")][offsetFinder(3, "row")] = 1;
	liveArray[offsetFinder(1, "col")][offsetFinder(3, "row")] = 1;
	liveArray[offsetFinder(4, "col")][offsetFinder(3, "row")] = 1;
	//

	liveArray[offsetFinder(-6, "col")][offsetFinder(4, "row")] = 1;
	liveArray[offsetFinder(-5, "col")][offsetFinder(4, "row")] = 1;
	liveArray[offsetFinder(-1, "col")][offsetFinder(4, "row")] = 1;
	liveArray[offsetFinder(0, "col")][offsetFinder(4, "row")] = 1;
	liveArray[offsetFinder(2, "col")][offsetFinder(4, "row")] = 1;
	liveArray[offsetFinder(3, "col")][offsetFinder(4, "row")] = 1;
	liveArray[offsetFinder(6, "col")][offsetFinder(4, "row")] = 1;
	liveArray[offsetFinder(7, "col")][offsetFinder(4, "row")] = 1;

	liveArray[offsetFinder(-8, "col")][offsetFinder(5, "row")] = 1;
	liveArray[offsetFinder(2, "col")][offsetFinder(5, "row")] = 1;
	liveArray[offsetFinder(3, "col")][offsetFinder(5, "row")] = 1;
	liveArray[offsetFinder(5, "col")][offsetFinder(5, "row")] = 1;
	liveArray[offsetFinder(6, "col")][offsetFinder(5, "row")] = 1;
	liveArray[offsetFinder(7, "col")][offsetFinder(5, "row")] = 1;
	liveArray[offsetFinder(8, "col")][offsetFinder(5, "row")] = 1;

	liveArray[offsetFinder(-9, "col")][offsetFinder(6, "row")] = 1;
	liveArray[offsetFinder(3, "col")][offsetFinder(6, "row")] = 1;
	liveArray[offsetFinder(4, "col")][offsetFinder(6, "row")] = 1;
	liveArray[offsetFinder(6, "col")][offsetFinder(6, "row")] = 1;
	liveArray[offsetFinder(7, "col")][offsetFinder(6, "row")] = 1;
	liveArray[offsetFinder(8, "col")][offsetFinder(6, "row")] = 1;

	liveArray[offsetFinder(-9, "col")][offsetFinder(7, "row")] = 1;
	liveArray[offsetFinder(-4, "col")][offsetFinder(7, "row")] = 1;
	liveArray[offsetFinder(4, "col")][offsetFinder(7, "row")] = 1;
	liveArray[offsetFinder(5, "col")][offsetFinder(7, "row")] = 1;

	liveArray[offsetFinder(-9, "col")][offsetFinder(8, "row")] = 1;
	liveArray[offsetFinder(-8, "col")][offsetFinder(8, "row")] = 1;
	liveArray[offsetFinder(-7, "col")][offsetFinder(8, "row")] = 1;
	liveArray[offsetFinder(-6, "col")][offsetFinder(8, "row")] = 1;
	liveArray[offsetFinder(-5, "col")][offsetFinder(8, "row")] = 1;

	tick();
}

function makeGosperGun() {
	reset();

	// === === START === ===

	// [COLUMN, ROW]
	// === Left Square ===
	liveArray[offsetFinder(-17, "col")][offsetFinder(0, "row")] = 1;
	liveArray[offsetFinder(-17, "col")][offsetFinder(-1, "row")] = 1;
	liveArray[offsetFinder(-16, "col")][offsetFinder(0, "row")] = 1;
	liveArray[offsetFinder(-16, "col")][offsetFinder(-1, "row")] = 1;

	// === Left Right Smooth Pointer ===
	liveArray[offsetFinder(-7, "col")][offsetFinder(-1, "row")] = 1;
	liveArray[offsetFinder(-7, "col")][offsetFinder(0, "row")] = 1;
	liveArray[offsetFinder(-7, "col")][offsetFinder(1, "row")] = 1;

	liveArray[offsetFinder(-6, "col")][offsetFinder(-2, "row")] = 1;
	liveArray[offsetFinder(-6, "col")][offsetFinder(2, "row")] = 1;

	liveArray[offsetFinder(-5, "col")][offsetFinder(-3, "row")] = 1;
	liveArray[offsetFinder(-5, "col")][offsetFinder(3, "row")] = 1;

	liveArray[offsetFinder(-4, "col")][offsetFinder(-3, "row")] = 1;
	liveArray[offsetFinder(-4, "col")][offsetFinder(3, "row")] = 1;

	// Eye
	liveArray[offsetFinder(-3, "col")][offsetFinder(0, "row")] = 1;

	liveArray[offsetFinder(-2, "col")][offsetFinder(-2, "row")] = 1;
	liveArray[offsetFinder(-2, "col")][offsetFinder(2, "row")] = 1;

	liveArray[offsetFinder(-1, "col")][offsetFinder(-1, "row")] = 1;
	liveArray[offsetFinder(-1, "col")][offsetFinder(0, "row")] = 1;
	liveArray[offsetFinder(-1, "col")][offsetFinder(1, "row")] = 1;

	// Nose
	liveArray[offsetFinder(0, "col")][offsetFinder(0, "row")] = 1;

	// === Right Complex Block ===
	liveArray[offsetFinder(3, "col")][offsetFinder(-3, "row")] = 1;
	liveArray[offsetFinder(3, "col")][offsetFinder(-2, "row")] = 1;
	liveArray[offsetFinder(3, "col")][offsetFinder(-1, "row")] = 1;

	liveArray[offsetFinder(4, "col")][offsetFinder(-3, "row")] = 1;
	liveArray[offsetFinder(4, "col")][offsetFinder(-2, "row")] = 1;
	liveArray[offsetFinder(4, "col")][offsetFinder(-1, "row")] = 1;

	liveArray[offsetFinder(5, "col")][offsetFinder(-4, "row")] = 1;
	liveArray[offsetFinder(5, "col")][offsetFinder(0, "row")] = 1;

	liveArray[offsetFinder(7, "col")][offsetFinder(-5, "row")] = 1;
	liveArray[offsetFinder(7, "col")][offsetFinder(-4, "row")] = 1;
	liveArray[offsetFinder(7, "col")][offsetFinder(0, "row")] = 1;
	liveArray[offsetFinder(7, "col")][offsetFinder(1, "row")] = 1;

	// === Right Square ===
	liveArray[offsetFinder(17, "col")][offsetFinder(-2, "row")] = 1;
	liveArray[offsetFinder(17, "col")][offsetFinder(-3, "row")] = 1;
	liveArray[offsetFinder(18, "col")][offsetFinder(-2, "row")] = 1;
	liveArray[offsetFinder(18, "col")][offsetFinder(-3, "row")] = 1;

	// === === END === ===
}

function makePufferfish() {
	reset();

	// === === START === ===
	// [COLUMN, ROW]
	// LEFT SIDE
	liveArray[offsetFinder(-6, "col")][offsetFinder(2, "row")] = 1;
	liveArray[offsetFinder(-6, "col")][offsetFinder(3, "row")] = 1;

	liveArray[offsetFinder(-5, "col")][offsetFinder(-3, "row")] = 1;
	liveArray[offsetFinder(-5, "col")][offsetFinder(3, "row")] = 1;

	liveArray[offsetFinder(-4, "col")][offsetFinder(-4, "row")] = 1;
	liveArray[offsetFinder(-4, "col")][offsetFinder(-3, "row")] = 1;
	liveArray[offsetFinder(-4, "col")][offsetFinder(1, "row")] = 1;

	liveArray[offsetFinder(-3, "col")][offsetFinder(-5, "row")] = 1;
	liveArray[offsetFinder(-3, "col")][offsetFinder(-4, "row")] = 1;
	liveArray[offsetFinder(-3, "col")][offsetFinder(-2, "row")] = 1;
	liveArray[offsetFinder(-3, "col")][offsetFinder(5, "row")] = 1;

	liveArray[offsetFinder(-2, "col")][offsetFinder(-4, "row")] = 1;
	liveArray[offsetFinder(-2, "col")][offsetFinder(-2, "row")] = 1;
	liveArray[offsetFinder(-2, "col")][offsetFinder(0, "row")] = 1;
	liveArray[offsetFinder(-2, "col")][offsetFinder(6, "row")] = 1;

	liveArray[offsetFinder(-1, "col")][offsetFinder(-3, "row")] = 1;
	liveArray[offsetFinder(-1, "col")][offsetFinder(-2, "row")] = 1;
	liveArray[offsetFinder(-1, "col")][offsetFinder(1, "row")] = 1;
	liveArray[offsetFinder(-1, "col")][offsetFinder(5, "row")] = 1;

	liveArray[offsetFinder(0, "col")][offsetFinder(2, "row")] = 1;
	liveArray[offsetFinder(0, "col")][offsetFinder(3, "row")] = 1;
	liveArray[offsetFinder(0, "col")][offsetFinder(4, "row")] = 1;

	// RIGHT SIDE
	liveArray[offsetFinder(2, "col")][offsetFinder(2, "row")] = 1;
	liveArray[offsetFinder(2, "col")][offsetFinder(3, "row")] = 1;
	liveArray[offsetFinder(2, "col")][offsetFinder(4, "row")] = 1;

	liveArray[offsetFinder(3, "col")][offsetFinder(-3, "row")] = 1;
	liveArray[offsetFinder(3, "col")][offsetFinder(-2, "row")] = 1;
	liveArray[offsetFinder(3, "col")][offsetFinder(1, "row")] = 1;
	liveArray[offsetFinder(3, "col")][offsetFinder(5, "row")] = 1;

	liveArray[offsetFinder(4, "col")][offsetFinder(-4, "row")] = 1;
	liveArray[offsetFinder(4, "col")][offsetFinder(-2, "row")] = 1;
	liveArray[offsetFinder(4, "col")][offsetFinder(0, "row")] = 1;
	liveArray[offsetFinder(4, "col")][offsetFinder(6, "row")] = 1;

	liveArray[offsetFinder(5, "col")][offsetFinder(-5, "row")] = 1;
	liveArray[offsetFinder(5, "col")][offsetFinder(-4, "row")] = 1;
	liveArray[offsetFinder(5, "col")][offsetFinder(-2, "row")] = 1;
	liveArray[offsetFinder(5, "col")][offsetFinder(5, "row")] = 1;
//
	liveArray[offsetFinder(6, "col")][offsetFinder(-4, "row")] = 1;
	liveArray[offsetFinder(6, "col")][offsetFinder(-3, "row")] = 1;
	liveArray[offsetFinder(6, "col")][offsetFinder(1, "row")] = 1;

	liveArray[offsetFinder(7, "col")][offsetFinder(-3, "row")] = 1;
	liveArray[offsetFinder(7, "col")][offsetFinder(3, "row")] = 1;

	liveArray[offsetFinder(8, "col")][offsetFinder(2, "row")] = 1;
	liveArray[offsetFinder(8, "col")][offsetFinder(3, "row")] = 1;


	// === === END === ===
}

/**
 * @function initListeners
 * setup the listeners for the controller buttons
 */
function initListeners() {
	document.getElementById('btnGolReset').addEventListener('click', reset);
	document.getElementById('btnGolRandom').addEventListener('click', fillRandom);
	document.getElementById('btnGolCopperhead').addEventListener('click', makeCopperhead);
	document.getElementById('btnGolHammerhead').addEventListener('click', makeHammerhead);
	document.getElementById('btnGolGosper').addEventListener('click', makeGosperGun);
	document.getElementById('btnPufferfish').addEventListener('click', makePufferfish);
}

/**
 * @function init
 * Initialise code
 */
export default function init() {
	gridHeight = 400;
	gridWidth = 400;
	liveArray = createArray(gridWidth);
	tempArray = createArray(gridWidth);
	c = document.getElementById("golCanvas");
	ctx = c.getContext("2d");
	ctx.fillStyle = "#bf8413";

	initListeners();

	// init main loop
	tick();
}
